const authReducer = (state = {}, action) => {
  switch (action.type) {
    case "login":
      const accessToken = action.payload.token;
      localStorage.setItem("accessToken", accessToken);
      let role = "";
      if (action.payload.role) {
        role = JSON.stringify(action?.payload?.role);
      }
      localStorage.setItem("userRoles", role);

      if (action.payload.role.id !== 1) {
        localStorage.setItem(
          "gameStudioId",
          JSON.stringify(action.payload.result.gs_id)
        );
      }
      return { ...state, accessToken };
    case "loginWithGoogle":
      const accessToke = action.payload.token;
      let role1 = "";
      if (action.payload.role) {
        role1 = JSON.stringify(action?.payload?.role);
      }
      localStorage.setItem("accessToken", accessToke);
      localStorage.setItem("userRoles", role1);
      if (action.payload.role.id !== 1) {
        localStorage.setItem(
          "gameStudioId",
          JSON.stringify(action.payload.result.gs_id)
        );
      }
      return { ...state, accessToken };
    case "logout":
      localStorage.removeItem("accessToken");
      localStorage.removeItem("userRoles");
      localStorage.removeItem("sessionToken");
      localStorage.removeItem("gameStudioId");
      return {};
    default:
      return state;
  }
};

export default authReducer;
