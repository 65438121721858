import React, { useState, useEffect } from "react";
import Navigation from "./../components/Navigation/Navigation";
import { Row, Breadcrumb, Col, Badge } from "react-bootstrap";
import Footer from "./Footer/Footer";
import Loader from ".././components/common/Loader";
import rewards from ".././assets/img/rewards.png";
import launches from ".././assets/img/Launches.png";
import hours from ".././assets/img/Hours.png";
import installs from ".././assets/img/installs.png";
import social from ".././assets/img/social.png";
import Chart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import http from "../utils/Api"; // Import your HTTP library
import Cookies from "js-cookie";
import { getUsersPermission } from "../service/actions/usersActions";
import { getSubscriptions } from "../service/actions/subscriptionAction";
import { PenTool } from "react-feather";

const Dashboard = () => {
  const [sideMenu, setSideMenu] = useState(true);
  const [loading, setLoading] = useState(true);
  const games = useSelector((state) => state.game);
  const packageNames = games.map((item) => item.packageName);
  const gameStudio = useSelector((state) => state.gameStudio);

  const token = localStorage.getItem("accessToken");
  const usersPermission = useSelector((state) => state.userPermission);
  const dispatch = useDispatch();
  const [chartDat, setChartData] = useState({
    launches: 0,
    install: 0,
    rewards: 0,
    minutes: 0,
  });

  const fetchData = async () => {
    setLoading(true);

    if (packageNames.length > 0) {
      try {
        const apiUrl = `/api/game/gameStudiosGamesStatistics`;
        const response = await http.post(
          apiUrl,
          {
            packageNameArray: packageNames,
          },
          http.generateConfig(token)
        );
       await response.result.rewards ? delete response.result.rewards: response.result.rewards =0 
        setChartData(response.result);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    } else {
      // Handle the case where packageNames is empty
      setLoading(false);
    }
  };

  useEffect(() => {

    dispatch(getSubscriptions({ email: 'azhar@gmail.com' }))
  }, [])

  useEffect(() => {
    fetchData();
  }, [games]);

  const chartData = {
    options: {
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      chart: {
        id: "dummy-chart",
        toolbar:{
          tools: {
            pan : true
          },
        },
        pan: {
          enabled: true,
        }
      },
      
      dataLabels: {
        style: {
          colors: ['rgb(121, 121, 121)']
        }
      },
      xaxis: {
        categories: Object.keys(chartDat).map((key) => {
          const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
          return capitalizedKey;
        }),
        labels: {
          style: {
            fontSize: '12px', 
          },
        },
      },
      tooltip: {
        x: {
          formatter: function (val) {
            return val.charAt(0).toUpperCase() + val.slice(1);
          },
        },
        y: {
          formatter: function (val) {
            return '<span style="color: grey;">' + parseFloat(val).toFixed(2) + '</span>';
          },
        },
      },
   
      responsive: [
        {
          breakpoint: 480,
          options: {
            xaxis: {
              labels: {
                rotate: -45,
                style: {
                  fontSize: '10px'
                }
              }
            }
          }
        }
      ]
    },
    series: [
      {
        name: "GlobalKpi",
        data: Object.values(chartDat).map(value => { return parseFloat(value).toFixed(2) }),
        
      },
    ],
  };




  useEffect(() => {
    const myInterval = setInterval(() => {
      setLoading(false);
    }, 1000);

    return () => {
      clearInterval(myInterval);
    };
  }, []);

  const onSideMenu = (active) => {
    setSideMenu(active);
  };

  let loader = null;
  if (loading) {
    loader = <Loader message="Loading..." />;
  }

  return (
    <>
      <div className="page-wrapper">
        {/* Navigation */}
        <Navigation onClick={onSideMenu} />
        {/* End Navigation */}

        <div
          className={`main-content d-flex flex-column ${sideMenu ? "hide-sidemenu" : ""
            }`}
        >
          {/* Loader */}
          {loader}
          {/* End Loader */}

          {/* Breadcrumb */}
          <div className="main-content-header">
            <Breadcrumb>
              <h1>Home</h1>
            </Breadcrumb>
          </div>
          {/* End Breadcrumb */}

          <div
            className="max-width-container"
            style={{ width: "100%", maxWidth: "90%", margin: "0 auto" }}
          >
            <Row>
              <Col md={4}>
                <div className="stats-card  mb-4">
                  <div className="col-main-style">
                    <div>
                      <img
                        src={launches}
                        alt="launches"
                        style={{ marginRight: "15px" }}
                      />
                      <div className="col-child-text-style">
                        <p>Launches</p>
                        <h6>{chartDat.launches}</h6>
                      </div>
                    </div>

                  </div>
                  <hr />

                  <div className="col-main-style">
                    <div>
                      <img
                        src={hours}
                        alt="hours"
                        style={{ marginRight: "15px" }}
                      />
                      <div className="col-child-text-style">
                        <p>Minutes Played</p>
                        <h6>{chartDat.minutes}</h6>
                      </div>
                    </div>

                  </div>
                  <hr />

                  {/* <div className="col-main-style">
                    <div>
                      <img
                        src={rewards}
                        alt="rewards"
                        style={{ marginRight: "15px" }}
                      />
                      <div className="col-child-text-style">
                        <p>Rewards Awarded</p>
                        <h6>{chartDat.rewards.toFixed(2)}</h6>
                      </div>
                    </div>

                  </div> */}

                  {/* <hr /> */}
                  <div className="col-main-style">
                    <div>
                      <img
                        src={installs}
                        alt="installs"
                        style={{ marginRight: "15px" }}
                      />
                      <div className="col-child-text-style">
                        <p>Installs</p>
                        <h6>{chartDat.install}</h6>
                      </div>
                    </div>

                  </div>
                  <hr />
                  <div className="col-main-style">
                    <div>
                      <img
                        src={social}
                        alt="social"
                        style={{ marginRight: "15px" }}
                      />
                      <div className="col-child-text-style">
                        <p>Social Sharing</p>
                        <h6>Unlock this feature</h6>
                      </div>
                    </div>

                  </div>
                </div>
              </Col>
              <Col md={8}>
                <div className="stats-card mb-4">
                  <Chart
                    options={chartData.options}
                    series={chartData.series}
                    type="bar"
                    height={350}
                  />
                </div>
              </Col>
            </Row>
          </div>

          {/* Footer */}
          <div className="flex-grow-1"></div>
          <Footer />
          {/* End Footer */}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
