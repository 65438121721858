export const add = "addGame";
export const get = "getGame";
export const getAll = "getStudioGames";
export const update = "updateGame";
export const remove = "removeGame";

export const addUsers = "addUser";
export const getUser = "getUser";
export const getAllUsers = "getAllUsers";
export const getAllUsersPermission = "getAllUsersPermission";
export const updateUsers = "updateUser";
export const removeUser = "removeuser";

export const getSingleUserById = "getSingleUserById";

export const getGameStudios = "getGameStudio";
export const updateGameStudios = "updateGameStudio";

export const loginType = "login";
export const registerType = "register";


export const getAllSubscription ="getAllSubscription"