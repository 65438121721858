import axios from "axios";
import http from "../../utils/Api";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { getAllSubscription } from "../actionTypes/types";


const getSubscriptions =(data)=>async(dispatch)=>{
    try{
        const res = await axios.post("http://localhost:3003/api/getSubscription", data);
        if (res) {
          dispatch({
            type: getAllSubscription,
            payload: res,
          });
        }
    }
    catch(err){
        console.log(err)
    }
}

export {
    getSubscriptions
}