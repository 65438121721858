import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div
    className="termAndCond" style={{
        textAlign: 'left',
        padding: '5rem'
    }}>
      <h1
      style={{
        textAlign: 'center',
        textDecoration: 'underline'
    }}>Privacy Policy For Earnscape</h1>
      <p>Last Updated: June 19, 2023</p>
      <p>
        This privacy policy (“Policy”) describes how Earnscape and its related
        companies (“Company”) collect, use and share personal information of
        consumer users of this mobile application Earnscape (“App”). This Policy
        also applies to any of our other websites that post this Policy. This
        Policy does not apply to websites that post different statements.
      </p>

      <h2>WHAT WE COLLECT</h2>
      <p>
        <strong>Information You Give Us:</strong> We collect your telephone
        number and other information you directly give us on our App. We use
        this information to create an account for you using a One Time Password
        (OTP) sent to your telephone number.
      </p>
      <p>
        <strong>Information We Get From Others:</strong> We may get information
        about you from other sources. We may add this to information we get from
        this App.
      </p>
      <p>
        <strong>Information Automatically Collected:</strong> We automatically
        log information about you and your device. For example, when using our
        App, we log your device’s operating system type, language, and the dates
        and times of access.
      </p>
      <p>
        <strong>Cookies:</strong> We may log information using “cookies”.
        Cookies are small data files stored on your device by a website.
      </p>

      <h2>USE OF PERSONAL INFORMATION</h2>
      <p>
        We use your personal information to operate, maintain, and improve our
        sites, products, and services. We use your personal information to:
      </p>
      <ul>
        <li>Create your account, through the OTP authentication.</li>
        <li>Process transactions you have made.</li>
        <li>Respond to comments and questions and provide customer service.</li>
        <li>
          Send information including confirmations, invoices, technical notices,
          updates, security alerts, and support and administrative messages.
        </li>
        <li>
          Communicate about promotions, upcoming events, and other news about
          products and services offered by us and our selected partners.
        </li>
        <li>Link or combine user information with other personal information.</li>
        <li>
          Protect, investigate, and deter against fraudulent, unauthorized, or
          illegal activity.
        </li>
      </ul>

      <h2>SHARING OF PERSONAL INFORMATION</h2>
      <p>
        We do not share your personal information with third parties without
        your consent, except in the following circumstances or as described in
        this Policy:
      </p>
      <ul>
        <li>
          <strong>Service Providers:</strong> We may share your personal
          information with third parties for the purpose of providing services
          to us. These third parties are authorized to use your personal
          information only as necessary to provide these services to us.
        </li>
        <li>
          <strong>
            Compliance with Laws and Law Enforcement Requests; Protection of Our
            Rights:
          </strong>{' '}
          We may disclose your personal information as required by law, such as
          to comply with a subpoena, or similar legal process. We may also
          disclose your personal information when we believe in good faith that
          disclosure is necessary to protect our rights, protect your safety or
          the safety of others, investigate fraud, or respond to a government
          request.
        </li>
      </ul>

      <h2>INFORMATION CHOICES AND CHANGES</h2>
      <p>
        Our marketing emails tell you how to “opt-out.” If you opt out, we may
        still send you non-marketing emails. Non-marketing emails include emails
        about your accounts and our business dealings with you.
      </p>

      <h2>Contact Information</h2>
      <p>
        We welcome your comments or questions about this privacy policy. You may
        contact us at support@earnscape.io
      </p>

      <h2>Changes to this Privacy Policy</h2>
      <p>
        We may change this privacy policy. If we make any changes, we will
        change the Last Updated date above. Any questions regarding our Privacy
        Policy should be directed to our Data Protection Officer at
        support@earnscape.io.
      </p>

      <h2>END OF PRIVACY POLICY</h2>
    </div>
  );
};

export default PrivacyPolicy;
