import React from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import * as Icon from "react-feather";

const UserList = ({ users, onToggleState, onEditUser, onDeleteUser }) => {
  return (
    <div className="user-list">
      <div className="form-content  form-content-adduser">
        <h3>USERS LIST</h3>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>State</th>
              <th>Role</th>
              <th>Action</th>
              <th> </th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user.id}>
                <td>
                  {user.first_name} {user.last_name}
                </td>
                <td>{user.email}</td>
                <td style={{ fontWeight: "bold" }}>
                  {user.status.toUpperCase()}
                </td>
                <td>
                  {" "}
                  {user.role_id === 2
                    ? "ADMIN"
                    : user.role_id === 3
                    ? "MONITOR"
                    : user.role_id === 4
                    ? "MANAGER"
                    : "Unknown"}
                </td>
                <td>
                  <Button
                    variant={user.status === "active" ? "danger" : "success"}
                    onClick={() => onToggleState(user)}
                  >
                    {user.status === "active" ? "Suspend" : "Activate"}
                  </Button>
                </td>
                <td>
                  <Icon.Edit
                    className="icon"
                    onClick={() => onEditUser(user)}
                  />{" "}
                </td>
                <td>
                  <Icon.Trash2
                    className="icon"
                    onClick={() => onDeleteUser(user)}
                  />{" "}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default UserList;
