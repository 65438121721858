import http from "../../utils/Api";
import {
  addUsers,
  getUser,
  getAllUsers,
  updateUsers,
  removeUser,
  getAllUsersPermission,
} from "../actionTypes/types";
import "react-toastify/dist/ReactToastify.css";
import jwt_decode from "jwt-decode";
import axios from "axios";
import { toast } from "react-toastify";

const getStudioUsers = (data) => async (dispatch) => {
  try {
    const token = localStorage.getItem("accessToken");
    // console.log(http.generateConfig(token),'theadsjakl')
    let { id } = jwt_decode(token);
    const res = await http.get(
      `/api/getGameStudioUsers/${id}`,
      data,
      http.generateConfig(token)
    );
    if (res) {
      dispatch({
        type: getAllUsers,
        payload: res.users,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

const getUsersPermission = () => async (dispatch) => {
  try {
    let { id, role_name } = JSON.parse(localStorage.getItem("userRoles"));
    const res = await http.get(`/api/getRolePermissions/${id}`);
    if (res) {
      dispatch({
        type: getAllUsersPermission,
        payload: res.result,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

const getSingleuser = (data) => async (dispatch) => {
  try {
    const token = localStorage.getItem("accessToken");

    const { id } = data; //game ID
    const res = await http.get(
      `/api/game/${id}`,
      data,
      http.generateConfig(token)
    );
    dispatch({
      type: getUser,
      payload: res,
    });
  } catch (error) {
    console.log(error);
  }
};
const addUser = (data) => async (dispatch) => {
  try {
    const token = localStorage.getItem("accessToken");
    let { id } = jwt_decode(token);
    const dat = await http.post(
      `/api/createUserWithRole`,
      { ...data, gameStudioId: id },
      http.generateConfig(token)
    );

    if (dat) {
      dispatch({
        type: addUsers,
        payload: dat.result,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

const updateUser = (data) => async (dispatch) => {
  try {
    const token = localStorage.getItem("accessToken");

    const { id } = data;
    const dat = await http.put(
      `/api/updateUserById/${id}`,
      data,
      http.generateConfig(token)
    );
    if (dat) {
      dispatch({
        type: updateUsers,
        payload: dat.result,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

const deleteUser = (data) => async (dispatch) => {
  try {
    // const { id } = data; //game ID
    const token = localStorage.getItem("accessToken");
    let { id } = jwt_decode(token);
    const dat = await http.post(
      `/api/deleteUser`,
      {
        userId: data,
        gameStudioId: id,
      },
      http.generateConfig(token)
    );
    dispatch({
      type: removeUser,
      payload: dat?.result,
    });
    toast(dat.message, dat.success);
  } catch (err) {
    console.log(err);
  }
};

const getViralMarketingData = async (payload) => {
  try {
    const token = localStorage.getItem("accessToken");
    console.log(payload, "the body");
    const response = await http.post(
      "api/getBoostLogs",
      payload,
      http.generateConfig(token)
    );
    return response;
  } catch (err) {
    console.log("err:", err);
  }
};
const getAllPaidTiers = async () => {
  try {
    const response = await http.get("api/getAllPaidTiers");
    return response;
  } catch (err) {
    console.log("err:", err);
  }
};

export {
  getSingleuser,
  getStudioUsers,
  addUser,
  updateUser,
  deleteUser,
  getViralMarketingData,
  getAllPaidTiers,
  getUsersPermission,
};
