import React, { useState } from "react";
import { Row, Col, Breadcrumb, Button, Form, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import Navigation from "../components/Navigation/Navigation";
import Footer from "./Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import {
  upateGameStudio,
  updateGameStudio,
} from "../service/actions/gameStudioActions";

const Settings = () => {
  const gameStudio = useSelector((state) => state.gameStudio);
  const [sideMenu, setSideMenu] = useState(true);
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({
    gs_name: gameStudio?.gs_name,
    address: gameStudio?.address,
    contact_phone: gameStudio?.contact_phone,
    // email: "",
    description: gameStudio?.description,
    // profilePicture: null,
  });
  const dispatch = useDispatch();
  // Toggle side bar menu
  const toggleSideMenu = (active) => {
    setSideMenu(active);
  };

  const handleChange = (event) => {
    const { name, value, type } = event.target;
    if (type === "file") {
      setFormData({
        ...formData,
        [name]: event.target.files[0],
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  // const handleRemoveImage = () => {
  //   setFormData({
  //     ...formData,
  //     profilePicture: null,
  //   });
  // };

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      // Dispatch your form submission action here
      dispatch(updateGameStudio(formData, setFormData));
    }
    setValidated(true);
  };

  return (
    <div className="page-wrapper">
      {/* Navigation */}
      <Navigation onClick={toggleSideMenu} />
      {/* End Navigation */}

      <div
        className={`main-content d-flex flex-column ${
          sideMenu ? "hide-sidemenu" : ""
        }`}
      >
        {/* Breadcrumb */}
        <div className="main-content-header">
          <Breadcrumb>
            <h1>Profile Settings</h1>
            <Link to="/home" className="breadcrumb-item">
              Home
            </Link>
            <Breadcrumb.Item active>Profile Settings</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        {/* End Breadcrumb */}

        {/* Profile Settings */}
        <Row>
          <Col lg={12}>
            <div className="profile-settings-form mb-3">
              <Form
                noValidate
                validated={validated}
                onSubmit={handleSubmit}
                style={{ textAlign: "left" }}
              >
                {/* <Form.Group className="mb-3">
                  <div className="image-upload">
                    <div className="image-preview">
                      <div className="profile-picture-container">
                        {formData.profilePicture && (
                          <Image
                            src={URL.createObjectURL(formData.profilePicture)}
                            alt="Profile Preview"
                            roundedCircle
                            style={{ width: "60px", height: "60px" }}
                          />
                        )}
                      </div>
                      {formData.profilePicture && (
                        <button
                          type="button"
                          className="remove-image"
                          onClick={handleRemoveImage}
                        >
                          Remove
                        </button>
                      )}
                    </div>
                    <Form.Control
                      type="file"
                      name="profilePicture"
                      className="file-input"
                      onChange={handleChange}
                    />
                  </div>
                  <Form.Text className="text-muted">
                    Picture size 80 x 80 pixels.
                  </Form.Text>
                </Form.Group> */}

                <Form.Group className="mb-3">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    name="gs_name"
                    value={formData.gs_name}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    required
                    as="textarea"
                    rows="3"
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Phone</Form.Label>
                  <Form.Control
                    required
                    type="number"
                    name="contact_phone"
                    value={formData.contact_phone}
                    onChange={handleChange}
                  />
                </Form.Group>

                {/* <Form.Group className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                  required
                    type="text"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </Form.Group> */}

                <div className="text-center">
                  <Button variant="primary" type="submit">
                    Update Profile
                  </Button>
                </div>
              </Form>
            </div>
          </Col>
        </Row>
        {/* End Profile Settings */}

        {/* Footer */}
        <div className="flex-grow-1"></div>
        <Footer />
        {/* End Footer */}
      </div>
    </div>
  );
};

export default Settings;
