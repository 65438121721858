import { initializeApp } from "firebase/app";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
// // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyCXuxFqZXoKJ6sXAKrUCHupqpg4Nb_MOM0",
//   authDomain: "theoneapp-396010.firebaseapp.com",
//   projectId: "theoneapp-396010",
//   storageBucket: "theoneapp-396010.appspot.com",
//   messagingSenderId: "605352358774",
//   appId: "1:605352358774:web:35280c90a0264b3f4c0057",
//   measurementId: "G-RTLTZES0T6"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {

  apiKey: "AIzaSyAQRP5PMN79Kqs1b97iymr4uWxJXIrHVNc",

  authDomain: "earnescape.firebaseapp.com",

  databaseURL: "https://earnescape-default-rtdb.firebaseio.com",

  projectId: "earnescape",

  storageBucket: "earnescape.appspot.com",

  messagingSenderId: "294070011146",

  appId: "1:294070011146:web:3148f1487b1242501c26f3",

  measurementId: "G-WM82SW0QL3"

};


const app = initializeApp(firebaseConfig);

const provider = new GoogleAuthProvider();
provider.setCustomParameters({   
  prompt : "select_account "
});

export const auth = getAuth();
const signInWithGooglePopup = () => signInWithPopup(auth, provider)
export {signInWithGooglePopup}