import React, { useEffect, useState } from "react";
import { Row, Col, Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import Navigation from "./../components/Navigation/Navigation";
import Footer from "./Footer/Footer";
import * as Icon from "react-feather";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import "./app.css";
import GameAnalytics from "./analytics/GameAnalyics";
import ViralMarketing from "./analytics/ViralMarketing";
import CustomRewards from "./analytics/CustomRewards";
import Billing from "./analytics/Billing";
import http from "../utils/Api";

const GamePage = () => {
  const [sideMenu, setSideMenu] = useState(true);
  const { gameId } = useParams();
  const games = useSelector((state) => state.game);
  const selectedGame = games.find((game) => game.id === parseInt(gameId));
  const [selectedComponent, setSelectedComponent] = useState("");
  // <GameAnalytics
  //   game={selectedGame}
  //   // analyticData={analyticData}
  // />
  const [analyticData, setAnalyticData] = useState({});
  const userPermission = useSelector((state) => state.userPermission);

  const toggleSideMenu = (active) => {
    setSideMenu(active);
  };
  const token = localStorage.getItem("accessToken");
  useEffect(() => {
    updateSelectedComponent("GameAnalytics");
    // setSelectedComponent("GameAnalytics");
  }, [selectedGame?.game_name]);
  // useEffect(() => {
  //   if (selectedGame) {
  //     const apiUrl = `/api/games/getgamebygameUrl/${selectedGame.packageName}`;
  //     http
  //       .get(apiUrl, http.generateConfig(token))
  //       .then((response) => response.json())
  //       .then((data) => {
  //         setAnalyticData(data.game);
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching data:", error);
  //       });
  //   }
  // }, [selectedGame]);

  // Function to update the selected component and highlight the column
  const updateSelectedComponent = (componentType) => {
    switch (componentType) {
      case "GameAnalytics":
        setSelectedComponent(
          <GameAnalytics
            game={selectedGame}
            // analyticData={analyticData}
          />
        );
        break;
      case "CustomRewards":
        setSelectedComponent(<CustomRewards game={selectedGame} />);
        break;
      case "ViralMarketing":
        setSelectedComponent(<ViralMarketing />);
        break;
      case "Billing":
        setSelectedComponent(<Billing game={selectedGame} />);
        break;
      default:
        setSelectedComponent("");
    }

    // Remove the 'selected-column' class from all columns
    const columns = document.querySelectorAll(".col-main-style");
    columns.forEach((column) => {
      column?.classList?.remove("selected-column");
    });

    // Add the 'selected-column' class to the clicked column
    const clickedColumn = document.querySelector(`.${componentType}`);
    clickedColumn?.classList?.add("selected-column");
  };

  return (
    <div className="page-wrapper">
      {/* Navigation */}
      <Navigation onClick={toggleSideMenu} />
      {/* End Navigation */}

      <div
        className={`main-content d-flex flex-column ${
          sideMenu ? "hide-sidemenu" : ""
        }`}
      >
        {/* Breadcrumb */}
        <div className="main-content-header">
          <Breadcrumb>
            <h1>{selectedGame ? selectedGame.game_name : "Game Title"}</h1>
            <Link to="/home" className="breadcrumb-item">
              Home
            </Link>
            <Breadcrumb.Item active>{selectedGame?.game_name}</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        {/* End Breadcrumb */}

        {/* Content */}
        <div className="max-width-container">
          <Row>
            <Col md={4}>
              <div className="stats-card mb-4">
                {/*      Buttons to switch between components */}
                {userPermission?.find(
                  (value) =>
                    value.PermissionId === 1 || value.PermissionId === 3
                ) && (
                  <div
                    className={`col-main-style GameAnalytics ${
                      selectedComponent === "GameAnalytics"
                        ? "selected-column"
                        : ""
                    }`}
                    onClick={() => updateSelectedComponent("GameAnalytics")}
                  >
                    Game Analytics
                  </div>
                )}
                <div
                  style={{ display: "none" }}
                  className={`col-main-style ViralMarketing ${
                    selectedComponent === "ViralMarketing"
                      ? "selected-column"
                      : ""
                  }`}
                  onClick={() => updateSelectedComponent("ViralMarketing")}
                >
                  Viral Marketing
                </div>
                <div
                  style={{ display: "none" }}
                  className={`col-main-style CustomRewards ${
                    selectedComponent === "CustomRewards"
                      ? "selected-column"
                      : ""
                  }`}
                  onClick={() => updateSelectedComponent("CustomRewards")}
                >
                  Custom Rewards
                </div>
                <div
                  className={`col-main-style Billing ${
                    selectedComponent === "Billing" ? "selected-column" : ""
                  }`}
                  onClick={() => updateSelectedComponent("Billing")}
                >
                  Billing
                </div>
              </div>
            </Col>
            <Col md={8}>
              <div className="stats-card mb-4">
                {/* Render the selected component */}
                {selectedComponent}
              </div>
            </Col>
          </Row>
        </div>
        {/* End Content */}

        {/* Footer */}
        <div className="flex-grow-1"></div>
        <Footer />
        {/* End Footer */}
      </div>
    </div>
  );
};

export default GamePage;
