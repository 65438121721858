import React, { useState } from "react";
import { useAcceptJs } from "react-acceptjs";
import { Modal, Form, Button, Card } from "react-bootstrap";
import { toast } from "react-toastify";
import http from "../../utils/Api";
import axios from "axios";
function PaymentForm({
  showModal,
  closeModal,
  selectedTier,
  game,
  gameStudio,
  getGameSubscription
}) {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    cardNumber: "",
    expiration: "",
    cvv: "",
    email: gameStudio?.contact_email ? gameStudio.contact_email : "",
  });
  const [paymentData, setPaymentData] = useState(null);
  const [formErrors, setFormErrors] = useState({});

  const handleInputChange = (event, field) => {
    setFormData((prevState) => ({
      ...prevState,
      [field]: event.target.value,
    }));
  };
  // 8jeRscn64BJ5h3qY7utpqH8BEHU6uEUn7tHYG9pdWjAVDg8vDyq45a4n44tK
  const { dispatchData, loading, error } = useAcceptJs({
    environment: "PRODUCTION",
    authData: {
      apiLoginID: "3JCj4y9r",
      clientKey:
        "8jeRscn64BJ5h3qY75F7utpqH8BEHU6uEUn7tHYG9pdWjAVDg8vRDyq45a4n44tK",
    },
  });

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const [month, year] = formData.expiration.split("/");

    if (!isValidMonth(month) || !isValidYear(year)) {
      toast.error("Error tokenizing card: " + "Invalid expiration date", {
        position: "top-right",
      });
      console.error("Invalid expiration date");
      return;
    }

    const cardData = {
      month: month,
      year: year,
      cardNumber: formData.cardNumber,
      cvv: formData.cvv,
      email: formData.email,
      // amount: parseInt(selectedTier?.monthly_fee)*100,
    };
    try {
      const response = await dispatchData({ cardData });

      if (response.messages.resultCode === "Ok") {
        setPaymentData(response.opaqueData.dataValue);

        const payload = {
          opaqueData: response.opaqueData,
          gameId: game.id,
          gameStudioId: gameStudio.id,
          tierId: selectedTier.id,
          first_name: formData.firstName,
          last_name: formData.lastName,
        };
        const res = await http.post(
          "/api/createsubscription",
          payload
        );
        if (res) {
          toast.success("Payment successful!", { position: "top-right" });
          setFormData({
            firstName: "",
            lastName: "",
            cardNumber: "",
            expiration: "",
            cvv: "",
            email: "",
          });
          getGameSubscription()
          closeModal()
          window.location.reload();
        }
      } else {
        if(response.messages){

          response.messages.message.forEach((errorMessage, index) => {
            toast.error(`Error ${index + 1}: ${errorMessage.text}`, {
              position: "top-right",
            });
          });
        }else{
          toast.error(
            `Error${error.message}`,
            { position: "top-right" }
          );
        }
      }
    } catch (error) {
      if (error.messages) {
        error.messages.message.forEach((errorMessage, index) => {
          toast.error(`Error ${index + 1}: ${errorMessage.text}`, {
            position: "top-right",
          });
        });
      } else {
        toast.error(
          `Error${error.message}`,
          { position: "top-right" }
        );
      }
    }
  };

  const isValidMonth = (month) => {
    return (
      /^[0-9]{2}$/.test(month) && parseInt(month) >= 1 && parseInt(month) <= 12
    );
  };

  const isValidYear = (year) => {
    return /^[0-9]{2}$/.test(year);
  };

  return (
    <Modal
      show={showModal}
      onHide={closeModal}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Payment Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          style={{
            display: "flex",
            flexFlow: "row",
            justifyContent: "space-between",
            padding: "0 10px 0 10px ",
          }}
        >
          <h6>
            <strong>{selectedTier?.tier_name.toUpperCase()}</strong>
          </h6>
          <h6>
            <strong>${selectedTier?.monthly_fee}</strong>
          </h6>
        </div>
        <hr />
        <Form onSubmit={handleFormSubmit}>
          <div className="d-flex">
            <Form.Group controlId="firstName" className="w-100">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                value={formData.firstName}
                onChange={(e) => handleInputChange(e, "firstName")}
                required
                isInvalid={!!formErrors.firstName}
              />
              <Form.Control.Feedback type="invalid">
                {formErrors.firstName}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="lastName" className="w-100">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                value={formData.lastName}
                onChange={(e) => handleInputChange(e, "lastName")}
                required
                isInvalid={!!formErrors.lastName}
              />
              <Form.Control.Feedback type="invalid">
                {formErrors.lastName}
              </Form.Control.Feedback>
            </Form.Group>
          </div>
          <Form.Group controlId="cardNumber">
            <Form.Label>Card Number</Form.Label>
            <Form.Control
              type="text"
              value={formData.cardNumber}
              onChange={(e) => handleInputChange(e, "cardNumber")}
              required
              isInvalid={!!formErrors.cardNumber}
            />
            <Form.Control.Feedback type="invalid">
              {formErrors.cardNumber}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="expiration">
            <Form.Label>Expiration Date (MM/YY)</Form.Label>
            <Form.Control
              type="text"
              value={formData.expiration}
              onChange={(e) => handleInputChange(e, "expiration")}
              required
              isInvalid={!!formErrors.expiration}
            />
            <Form.Control.Feedback type="invalid">
              {formErrors.expiration}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="cvv">
            <Form.Label>CVV</Form.Label>
            <Form.Control
              type="text"
              value={formData.cvv}
              onChange={(e) => handleInputChange(e, "cvv")}
              required
              isInvalid={!!formErrors.cvv}
            />
            <Form.Control.Feedback type="invalid">
              {formErrors.cvv}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="email">
            <Form.Label>Email Address</Form.Label>
            <Form.Control
              type="email"
              value={formData.email}
              onChange={(e) => handleInputChange(e, "email")}
              required
              isInvalid={!!formErrors.email}
            />
            <Form.Control.Feedback type="invalid">
              {formErrors.email}
            </Form.Control.Feedback>
          </Form.Group>
          <Button variant="primary" type="submit">
            Submit Payment
          </Button>
        </Form>
      </Modal.Body>
      <Modal.Footer className="text-center">
        <Button variant="secondary" onClick={closeModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default PaymentForm;
