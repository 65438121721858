import React, { useEffect, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import rewards from "../../assets/img/rewards.png";
import http from "../../utils/Api";
import totalRe from '../../assets/img/totalRe.png'
import plattimeRe from '../../assets/img/playtimeRe.png'

const CustomRewards = ({ game }) => {
  const [loading, setLoading] = useState(false);
  const [earningData, setEarningData] = useState({
    installReward:0,
    PlayTimeReward:0,
    totalRewardsGiven:0
  });
  const token = localStorage.getItem("accessToken");


  const fetchData = async () => {
    setLoading(true);

    try {
      const apiUrl = `/api/game/gameEarning/${game.packageName}`;

      
      const response = await http.get(apiUrl, http.generateConfig(token));

      setEarningData(response.result);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [game.packageName]);

  return (
    <div>
      <div style={{ textAlign: "left" }}>
        <h5>Custom Rewards</h5>
      </div>

      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          <div className="main-div">
            {/* Install Rewards */}
            <div className="main-div-child1">
              <img src={rewards} alt="Rewards" style={{ marginBottom: "10px" }} />
            </div>
            <div className="custom-each">
              <div className="custom-each-child">
                <h6>Install Rewards</h6>
                <h6>${earningData ? earningData.installReward : "N/A"}</h6>
              </div>
              <ProgressBar now={earningData ? earningData.installReward : 0} />
            </div>
          </div>
          <hr />

          <div className="main-div">
            {/* Playtime Reward */}
            <div className="main-div-child1">
              <img src={plattimeRe} alt="Rewards" style={{ marginBottom: "10px" }} />
            </div>
            <div className="custom-each">
              <div className="custom-each-child">
                <h6>Playtime Reward</h6>
                <h6>${earningData ? earningData.PlayTimeReward : "N/A"}</h6>
              </div>
              <ProgressBar now={earningData ? earningData.PlayTimeReward : 0} variant="success" />
            </div>
          </div>
          <hr />

          <div className="main-div">
            {/* Total Rewards Given */}
            <div className="main-div-child1">
              <img src={totalRe} alt="Rewards" style={{ marginBottom: "10px" }} />
            </div>
            <div className="custom-each">
              <div className="custom-each-child">
                <h6>Total Rewards Given</h6>
                <h6>${earningData ? earningData.totalRewardsGiven : "N/A"}</h6>
              </div>
              <ProgressBar now={earningData ? earningData.totalRewardsGiven : 0} variant="warning" />
            </div>
          </div>
        </>
      )}
      {/* Add your game analytics content here */}
    </div>
  );
};

export default CustomRewards;
