import http from "../../utils/Api";
import { getSingleUserById } from "../actionTypes/types";
import "react-toastify/dist/ReactToastify.css";
import jwt_decode from "jwt-decode";


const getSingleUserByIdAct = () => async (dispatch) => {
    try {
      const token = localStorage.getItem("accessToken");
      let { id } = jwt_decode(token);
      // console.log()
      const res = await http.get(
        `/api/getUserById/${id}`,
        http.generateConfig(token)
      );
      dispatch({
        type: getSingleUserById,
        payload: res,
      });
    } catch (error) {
      console.log(error);
    }
  };
export {getSingleUserByIdAct};
