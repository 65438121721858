import { add, get, getAll, update, remove } from "../actionTypes/types";

const gameReducer = (state = [], action) => {
  switch (action.type) {
    case add:
      // Assuming action.payload contains a single game object
      return [...state, action.payload];
    case get:
      // Find and return the game with the given ID
      return state.find((game) => game.id === action.payload.id);
    case getAll:
      // Assuming action.payload contains an array of game objects
      return action.payload;
    case update:
      // Find and replace the game with the updated one
      return state.map((game) =>
        game.id === action.payload.id ? action.payload.updatedGame : game
      );
    case remove:
      // Remove the game with the given ID from the state
      return state.filter((game) => game.id !== action.payload.id);
    default:
      return state;
  }
};

export default gameReducer;
