import http from "../../utils/Api";
import { add, get, getAll, update, remove } from "../actionTypes/types";
import "react-toastify/dist/ReactToastify.css";
import jwt_decode from "jwt-decode";

const getStudioGames = (data) => async (dispatch) => {
  try {
    const token = localStorage.getItem("accessToken");

    const gameStudioId = JSON.parse(localStorage.getItem("gameStudioId"));
    const userRoles = JSON.parse(localStorage.getItem("userRoles"));
    let { id } = jwt_decode(token);
    let studioId;
    if (userRoles.id === 1) {
      studioId = id;
    } else {
      studioId = gameStudioId;
    }

    const res = await http.get(
      `/api/game/getGameStudiosGames/${studioId}`,
      data,
      http.generateConfig(token)
    );
    if (res) {
      dispatch({
        type: getAll,
        payload: res.result,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

const getSingleGame = (data) => async (dispatch) => {
  try {
    const token = localStorage.getItem("accessToken");

    const { id } = data; //game ID
    const res = await http.get(
      `/api/game/${id}`,
      data,
      http.generateConfig(token)
    );
    console.log(res.data, "THE ASD");
    dispatch({
      type: get,
      payload: res,
    });
  } catch (error) {
    console.log(error);
  }
};
const addGames = (data) => async (dispatch) => {
  try {
    const token = localStorage.getItem("accessToken");
    let { id } = jwt_decode(token);
    const dat = await http.post(
      `/api/game/addGame/${id}`,
      data,
      http.generateConfig(token)
    );
    if (dat.result) {
      dispatch({
        type: add,
        payload: dat.result,
      });
    }
    //  link the game form the mobile bakcend
  } catch (err) {
    console.log(err);
  }
};

const updateGame = (data) => async (dispatch) => {
  try {
    const token = localStorage.getItem("accessToken");

    const { id } = data;
    const dat = await http.put(
      `/api/game/updateGame/${id}`,
      data,
      http.generateConfig(token)
    );
    dispatch({
      type: update,
      payload: dat,
    });
  } catch (err) {
    console.log(err);
  }
};

const deleteGame = (data) => async (dispatch) => {
  try {
    const token = localStorage.getItem("accessToken");

    const { id, gs_id } = data; //game ID
    const dat = await http.post(
      `/api/game/unlinkStudioGame/`,
      { gameId: id, studioId: gs_id },
      http.generateConfig(token)
    );
    dispatch({
      type: remove,
      payload: dat.result,
    });
    // change the tier of the game if its elevated
  } catch (err) {
    console.log(err);
  }
};

export { getStudioGames, getSingleGame, addGames, updateGame, deleteGame };
