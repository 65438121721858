import { getGameStudios, updateGameStudios } from "../actionTypes/types";

const initialState = null; // Initialize the state with null for a single object

const gameStudioReducer = (state = initialState, action) => {
  switch (action.type) {
    case getGameStudios:
      // Return the fetched game studio object
      return action.payload;
    case updateGameStudios:
      // Update the state with the updated game studio object
      if (state && state.id === action.payload.id) {
        return { ...state, ...action.payload };
      }
      return state;
    default:
      return state;
  }
};

export default gameStudioReducer;
