import React, { useState } from "react";
import { Row, Col, Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import Navigation from "./../components/Navigation/Navigation";
import Footer from "./Footer/Footer";
import * as Icon from "react-feather";
import { useSelector } from "react-redux";

const Profile = () => {
  const [sideMenu, setSideMenu] = useState(true);
  const gameStudio = useSelector((state) => state.gameStudio);
  const userProfile = useSelector((state) => state.userProfile);
  // Toggle side bar menu
  const toggleSideMenu = (active) => {
    setSideMenu(active);
  };

  return (
    <div className="page-wrapper">
      {/* Navigation */}
      <Navigation onClick={toggleSideMenu} />
      {/* End Navigation */}

      <div
        className={`main-content d-flex flex-column ${
          sideMenu ? "hide-sidemenu" : ""
        }`}
      >
        {/* Breadcrumb */}
        <div className="main-content-header">
          <Breadcrumb>
            <h1>Profile</h1>
            <Link to="/home" className="breadcrumb-item">
              Home
            </Link>
            <Breadcrumb.Item active>Profile</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        {/* End Breadcrumb */}

        {/* Feather Icons */}
        <Row>
          <Col lg={12}>
            <div className="profile-header mb-4">
              {/* <Image src={user1} alt="Profle" roundedCircle /> */}
              <h3 className="name mt-3">
                {gameStudio ? gameStudio?.gs_name.toUpperCase() : "ARON"}
              </h3>
              <p>
                {gameStudio?.description
                  ? gameStudio?.description
                  : `Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book.`}
              </p>

              <div className="profile-stats">
                <Link to="#">
                  <strong>{gameStudio ? gameStudio?.gameCount : 0}</strong>{" "}
                  Games
                </Link>
              </div>
            </div>
          </Col>
          <Col lg="3">
            <div className="profile-left-content">
              <div className="card mb-4">
                <div className="card-body">
                  <div className="card-header">
                    <h5 className="card-title">Info</h5>
                  </div>
                  <ul className="info">
                    <li>
                      <Icon.MapPin className="icon" />
                      Location: {gameStudio ? gameStudio?.address : `London`}
                    </li>
                    <li>
                      <Icon.Calendar className="icon" />
                      Joined:{" "}
                      {gameStudio
                        ? gameStudio.createdAt
                        : userProfile
                        ? userProfile.createdAt
                        : `Joined March 2019`}
                    </li>
                    <li>
                      <Icon.Phone className="icon" />
                      Phone:{" "}
                      {gameStudio
                        ? gameStudio.contact_phone
                        : `+0 (123) 456 7892`}
                    </li>
                    <li>
                      <Icon.Mail className="icon" />
                      Email:{" "}
                      {gameStudio
                        ? gameStudio.contact_email
                        : userProfile
                        ? userProfile.email
                        : `ONELAB@gmail.com`}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        {/* End Feather Icons */}

        {/* Footer */}
        <div className="flex-grow-1"></div>
        <Footer />
        {/* End Footer */}
      </div>
    </div>
  );
};

export default Profile;
