import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Form, Button, Image } from "react-bootstrap";
import * as Icon from "react-feather";
import { signin, signinGSWithGoogle } from "../service/actions/authActions";
// Logo image file path
import Logo from "../assets/img/earnscapeGreenlogo.png";
import phone from "../assets/img/phone.png";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { signInWithGooglePopup } from "../services/firebase";
import googleIcon from "../assets/img/googleIcon.png";
import { getUsersPermission } from "../service/actions/usersActions";
import { toast } from "react-toastify";

const Login = (props) => {
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onLoginHandler = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      dispatch(signin(formData)).then((data) => {
        const storedToken = localStorage.getItem("accessToken");
        if (data?.role?.id !== 1 && data?.result?.status === "suspended") {
          navigate("/suspended");
        } else if (storedToken) {
          toast.success("Login successful!", { position: "top-right" });
          navigate("/home");
        }
      });
    }
    setValidated(true);
  };
  const signInWithGoogle = async () => {
    try {
      const response = await signInWithGooglePopup();
      const data = {
        gs_name: response?.user?.providerData[0]?.displayName,
        contact_email: response?.user?.providerData[0].email,
        googleId: response?.user?.providerData[0].uid,
        profile_picture: response?.user?.providerData[0]?.photoURL,
      };
      dispatch(signinGSWithGoogle(data)).then(() => {
        const storedToken = localStorage.getItem("accessToken");
        if (storedToken) {
          navigate("/home");
        }
      });
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };

  return (
    <div className="auth-main-content">
      <div className="d-table">
        <div className="d-tablecell">
          <div>
            <Row className="align-items-center">
              <Col md={8} className="login-left-col">
                <div className="login-left">
                  <div className="login-left-inner">
                    <Image
                      src={Logo}
                      alt="Logo"
                      className="auth-logo1"
                      style={{ height: "50px", width: "60px" }}
                    />
                    <br />
                    <br />

                    <div
                      style={{
                        color: "white",
                        fontSize: "20px",
                        fontFamily: "Inter",
                        fontWeight: 600,
                        wordWrap: "break-word",
                      }}
                    >
                      Supercharge Your KPIs and eliminate
                      <br />
                      cheating with our AI protected solutions
                    </div>
                    <br />

                    <div
                      style={{
                        color: "white",
                        fontSize: "16px",
                        fontFamily: "Inter",
                        fontWeight: 400,
                        wordWrap: "break-word",
                      }}
                    >
                      Accelerate adoption for your games! Our system ensures
                      retention,
                      <br />
                      eliminates false conversions, and generates organic
                      marketing with ease
                    </div>

                    <div className="left-inner2">
                      <div style={{ textAlign: "center", marginRight: "10px" }}>
                        <div
                          style={{
                            color: "white",
                            fontSize: "16px",
                            fontFamily: "Inter",
                            fontWeight: 400,
                            wordWrap: "break-word",
                            marginBottom: "10px",
                            fontWeight: "600",
                          }}
                        >
                          Question?
                        </div>
                        <a
                          href="https://calendly.com/sawood/30min"
                          target="_blank"
                        >
                          <button>
                            Schedule a call with an account executive
                          </button>
                        </a>
                      </div>
                      <div style={{ height: "300px", width: "150px" }}>
                        <img
                          src={phone}
                          style={{
                            maxWidth: "100%",
                            maxHeight: "100%",
                            objectFit: "cover",
                          }}
                          alt="Phone"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Col>

              <Col md={4}>
                <Image
                  src={Logo}
                  alt="Logo"
                  className="auth-logo1"
                  style={{ height: "50px", width: "60px" }}
                />
                <div className="form-content">
                  <h1 className="heading">Sign In</h1>

                  <Form
                    noValidate
                    validated={validated}
                    onSubmit={onLoginHandler}
                    style={{ textAlign: "left" }}
                  >
                    <Form.Label>Email address</Form.Label>
                    <Form.Group className="mb-3">
                      <Form.Control
                        required
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        required
                        type="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                      />
                    </Form.Group>

                    <div
                      className="text-center"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "0.6rem",
                      }}
                    >
                      <Button variant="primary" type="submit">
                        Log In
                      </Button>
                      <h6>OR</h6>
                      <div className="text-center">
                        <Button
                          variant=""
                          type="button"
                          onClick={signInWithGoogle}
                          style={{
                            alignItems: "center",
                            border: "solid red 1px",
                            width: "100%",
                            borderRadius: "50px",
                            transition: "background-color 0.3s, color 0.3s", // Add transition effect
                          }}
                          className="google-login-button" // Add a class for styling
                        >
                          <img
                            src={googleIcon} // Use your Google icon path here
                            alt="Google"
                            style={{ width: "18px", marginRight: "8px" }}
                          />
                          Login with Google
                        </Button>
                      </div>

                      <div className="signup-text">
                        Don't have an account?{" "}
                        <Link to="/signup/" className="fp-link2">
                          Sign up
                        </Link>
                      </div>
                      <Link to="forget-password" className="fp-link">
                        Forgot Password?
                      </Link>
                    </div>
                  </Form>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
