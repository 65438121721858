import React from "react";
import { NavLink } from "react-router-dom";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import * as Icon from "react-feather";
import "./SideMenu.css";
import { useDispatch, useSelector } from "react-redux";
import { getStudioGames } from "../../../service/actions/gameActions";
import { useEffect } from "react";
import { getUsersPermission } from "../../../service/actions/usersActions";
import { getGameStudio } from "../../../service/actions/gameStudioActions";
import { getSingleUserByIdAct } from "../../../service/actions/userProfileAction";

const SideMenuLight = (props) => {
  const dispatch = useDispatch();
  const { id } = JSON.parse(localStorage.getItem("userRoles"));
  useEffect(() => {
    dispatch(getStudioGames());
    dispatch(getUsersPermission());
  }, []);

  useEffect(() => {
    if (id === 1) {
      dispatch(getGameStudio());
    } else {
      dispatch(getSingleUserByIdAct());
    }
  }, []);
  const games = useSelector((state) => state.game);
  const userPermission = useSelector((state) => state.userPermission);
  return (
    <div
      className={`sidemenu-area sidemenu-light ${
        props.sideMenu ? "" : "sidemenu-toggle"
      }`}
    >
      <Navbar className={`sidemenu ${props.sideMenu ? "" : "hide-nav-title"}`}>
        <Navbar.Collapse>
          <Nav>
            {/* <NavDropdown
              title={
                <div className="dropdown-title">
                  <Icon.Grid className="icon" />
                  <span className="title">
                    Home
                    <Icon.ChevronRight className="icon fr" />
                  </span>
                </div>
              }
              id="basic-nav-dropdown"
              >
              </NavDropdown> */}
            <NavLink
              to="/home/"
              className="nav-link"
              // activeClassName="drpMenu"
            >
              <div className="menu-item">
                <Icon.Grid className="icon" />
                <span className="title">Home</span>
              </div>
            </NavLink>

            {games.map((game) => (
              <NavLink
                key={game.id}
                to={`/game/${game.id}`} // Adjust the URL structure based on your needs
                className="nav-link"
              >
                <div className="menu-item">
                  <img src={game.game_icon} alt={""} className="icon" />
                  <span className="title">{game.game_name}</span>
                </div>
              </NavLink>
            ))}
            {userPermission?.find(
              (value) => value.PermissionId === 1 || value.PermissionId === 2
            ) && (
              <NavLink to="/addGame" className="nav-link">
                <Icon.Plus className="icon" />
                <span className="title">Add Games</span>
              </NavLink>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default SideMenuLight;

