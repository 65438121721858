import React from "react";
import Table from "react-bootstrap/Table";
import * as Icon from "react-feather";

const GameList = ({ games, onDeleteGame }) => {
  return (
    <div className="game-list">
      <div className="form-content  form-content-adduser">
        <h2>Game List</h2>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th> </th>
              <th>Title</th>
              {/* <th> </th> */}
              <th> </th>
            </tr>
          </thead>
          <tbody>
            {games.map((game) => (
              <tr key={game.id}>
                <td>
                  <img
                    src={game.game_icon}
                    alt={`${game.game_name} Icon`}
                    width="50"
                  />
                </td>
                <td>{game.game_name}</td>
                {/* <td>
                <Icon.Edit className="icon" 
                // onClick={() => onEditUser(user)}
                 />                  
                 </td> */}
                <td style={{ cursor: "pointer" }}>
                  <Icon.Trash2
                    className="icon-red-on-hover"
                    onClick={() => onDeleteGame(game)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default GameList;
