import http from "../../utils/Api";
import { getGameStudios, updateGameStudios } from "../actionTypes/types";
import "react-toastify/dist/ReactToastify.css";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";

const getGameStudio = (data) => async (dispatch) => {
  try {
    const token = localStorage.getItem("accessToken");
    let { id } = jwt_decode(token);
    const res = await http.get(
      `/api/getGameStudioById/${id}`,
      data,
      http.generateConfig(token)
    );
    if (res) {
      let gameCount = res.gameCount;
      let result = { ...res.result, gameCount };
      dispatch({
        type: getGameStudios,
        payload: result,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

const updateGameStudio = (data, setFormData) => async (dispatch) => {
  try {
    const token = localStorage.getItem("accessToken");
    let { id } = jwt_decode(token);
    const dat = await http.put(
      `/api/updateGameStudioById/${id}`,
      data,
      http.generateConfig(token)
    );
    if (dat) {
      dispatch({
        type: updateGameStudios,
        payload: dat,
      });
      setFormData({
        gs_name: "",
        address: "",
        contact_phone: "",
        // email: "",
        description: "",
        // profilePicture: null,
      });
      toast(dat.message, dat.success);
    }
  } catch (err) {
    console.log(err);
  }
};

export { getGameStudio, updateGameStudio };
