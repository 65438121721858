import { getAllSubscription } from "../actionTypes/types";

const initialState = [];

const StudioSubscription = (state = initialState, action) => {
  switch (action.type) {
    case getAllSubscription:
      return action.payload;
    default:
      return state;
  }
};


export default StudioSubscription