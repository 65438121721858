import React, { useEffect, useState } from "react";
import { Table, Badge, Pagination } from "react-bootstrap";
import { getViralMarketingData } from "../../service/actions/usersActions";

const ViralMarketing = () => {
  const [data, setData] = useState([]);
  const [status, setStatus] = useState(true);
  const totalPages = Math.ceil(data?.totalRecords / data?.pageSize);
  // Create an array of page numbers from 1 to totalPages
  const pageNumbers = Array.from(
    { length: totalPages },
    (_, index) => index + 1
  );
  const ViralMarketingGetData = async (payload = {}) => {
    const res = await getViralMarketingData(payload);
    setData(res);
  };

  function handlechange(payload) {
    ViralMarketingGetData(payload);
  }

  useEffect(() => {
    if (status) {
      ViralMarketingGetData();
    }
  }, []);

  // const marketingData = [
  //   {
  //     id: 1,
  //     name: "John Doe",
  //     postLink: "https://example.com/post/1",
  //     date: "2023-09-12",
  //     status: "Valid",
  //   },
  //   {
  //     id: 2,
  //     name: "Jane Smith",
  //     postLink: "https://example.com/post/2",
  //     date: "2023-09-15",
  //     status: "Pending",
  //   },
  //   {
  //     id: 2,
  //     name: "Jane Smith",
  //     postLink: "https://example.com/post/2",
  //     date: "2023-09-15",
  //     status: "Cancelled",
  //   },
  //   // Add more data as needed
  // ];

  const getStatusBadgeVariant = (status) => {
    switch (status) {
      case "used":
        return "badge badge-success"; // Green badge for "Valid" status
      case "pending":
        return "badge badge-info"; // Blue badge for "Pending" status
      case "expired":
        return "badge badge-secondary"; // Yellow badge for "Cancelled" status
      default:
        return "badge badge-danger"; // Gray badge for other statuses
    }
  };

  return (
    <div>
      <div style={{ textAlign: "left" }}>
        <h6>Viral Marketing</h6>
      </div>
      {/* Viral marketing History table */}
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            {/* <th>Name</th> */}
            <th>Post Link</th>
            <th>Date</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {data?.boostLogs?.map((item) => (
            <tr key={item.id}>
              <td>{item.id}</td>
              {/* <td>NULL</td> */}
              <td>
                <a
                  target="_blank"
                  href={`https://onelab.page.link/?amv=0&apn=com.onelab.oneapp&link=https%3A%2F%2F1lab.network%2F%3Frefrerral%3A${item.referral_code}`}
                >
                  https://onelab.page.link...
                </a>
              </td>
              <td>{item.createdAt}</td>
              <td>
                <span className={getStatusBadgeVariant(item.state)}>
                  {item.state}
                </span>
              </td>
            </tr>
          ))}
          <tr>
            <td colSpan={4}>
              <Pagination
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                {pageNumbers?.map((item) => {
                  return (
                    <Pagination.Item
                      active={item === data?.currentPage}
                      key={item}
                      onClick={() => handlechange({ page: item, pageSize: 10 })}
                    >
                      {item}
                    </Pagination.Item>
                  );
                })}
              </Pagination>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default ViralMarketing;
