import { useState } from "react";
import { Breadcrumb } from "react-bootstrap";
import { useSelector } from "react-redux";
import Footer from "./Footer/Footer";
import Navigation from "../components/Navigation/Navigation";
import Loader from "../components/common/Loader";
import { useLocation } from "react-router-dom";




const GameProfile = ()=>{
  const location = useLocation()
    const [sideMenu, setSideMenu] = useState(true);
    const [loading, setLoading] = useState(false);
    
  const onSideMenu = (active) => {
    setSideMenu(active);
  };
  let loader = null;
  if (loading) {
    loader = <Loader message="Loading..." />;
  }
    return(
        <div className="page-wrapper">
        {/* Navigation */}
        <Navigation onClick={onSideMenu} />
        {/* End Navigation */}
  
        <div
          className={`main-content d-flex flex-column ${sideMenu ? "hide-sidemenu" : ""}`}
        >
          {/* Breadcrumb */}
          <div className="main-content-header">
            <Breadcrumb>
              <h1>Home</h1>
            </Breadcrumb>
          </div>
          <div>
          {loading ? ( // Display a loading spinner while loading
           <></>
          ) : (
            <div className="game-details" style={{ width: "70%", margin: "0 auto", fontWeight: 600 }}>
              <h1>{location.state.name}</h1>
              <img src={location.state.game_icon} alt={location.state.name} style={{width:"100px"}}  />
              <p style={{ textAlign: "justify" }}>{location.state.gameDescription}</p>
              <p>Category: {location.state.gameCategory}</p>
              <p>Installs: {location.state.no_of_install}</p>
              <p>Launches: {location.state.no_of_launches}</p>
              <p>Clicks: {location.state.no_of_time_game_click}</p>
              <p>Impressions: {location.state.no_of_time_game_show}</p>
              {/* <p>Number of Launches: {gameData.no_of_launches}</p>
              <p>Number of Installs: {gameData.no_of_install}</p>
              <p>Gold Flakes Earned: {gameData.GoldFlakesEarnedInThisGame}</p>
              <p>Total Game Duration: {gameData.totalGameDuration.hours} hours, {gameData.totalGameDuration.minutes} minutes, {gameData.totalGameDuration.seconds} seconds</p> */}
            </div>
          )}
           
          </div>
          <Footer />
        </div>
      </div>
    )
}

export default GameProfile;