import React, { useEffect, useState } from "react";
// import { withRouter } from "react-router-dom";
import * as Icon from "react-feather";
import "./Navigation.css";
import {
  Navbar,
  Nav,
  NavDropdown,
  Container,
  Row,
  Col,
  Form,
  FormControl,
  Button,
  Image,
} from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import { getSingleUserByIdAct } from "../../service/actions/userProfileAction";

// Default dark side menu
// If you want to activate the light sidebar, uncomment below & comment above component
import SideMenuLight from "./SideMenu/SideMenu";

// Logo image path
import Logo from "../../assets/img/earnscapeIcon.png";
import ColorSwitch from "../common/colorSwitch";
// Profile & user image path
import profile from "../../assets/img/profile.jpg";
import { useDispatch, useSelector } from "react-redux";
import { getGameStudio } from "../../service/actions/gameStudioActions";
import { toast } from "react-toastify";
const Navigation = (props) => {
  const [sideMenu, setSideMenu] = useState(false);
  const [term, setTerm] = useState("");
  const gameStudio = useSelector((state) => state.gameStudio);
  const userProfile = useSelector((state) => state.userProfile);
  const toggleClass = () => {
    setSideMenu(!sideMenu);
    props.onClick(sideMenu);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (term) {
      props.history.push("/search/");
    }
  };
  const onSideMenuHandler = (activeColor) => {
    setSideMenu({ menuColor: activeColor });
  };
  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userRoles");
    toast("logout successfully", "success");
  };
  const dispatch = useDispatch();
  const userRole = JSON.parse(localStorage.getItem("userRoles"));
  useEffect(() => {
    if (userRole.id === 1) {
      dispatch(getGameStudio());
    } else {
      dispatch(getSingleUserByIdAct());
    }
  }, []);

  return (
    <div className="page-wrapper">
      <Navbar fixed="top" className="top-menu">
        <Link to="/home" className={`navbar-brand`}>
          {/* Large logo */}
          <Image
            src={Logo}
            alt="Logo"
            className="logo"
            style={{ width: "80px" }}
          />
        </Link>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        {/* Burger menu */}
        <div
          className={`burger-menu ${sideMenu ? "" : "toggle-menu"}`}
          onClick={toggleClass}
        >
          <span className="top-bar"></span>
          <span className="middle-bar"></span>
          <span className="bottom-bar"></span>
        </div>
        {/* End Burger menu */}

        <Navbar.Collapse id="basic-navbar-nav">
          {/* <Nav className="left-nav d-none d-md-block">
              <NavDropdown
                title={
                  <div className="mega-menu-btn">
                    Mega Menu
                    <Icon.ChevronDown className="icon" />
                  </div>
                }
                id="basic-nav-dropdown"
                className="mega-menu"
              >
                <Container>
                  <Row>
                    <Col md={6} lg={3}>
                      <h5 className="title">Features</h5>
                      <NavLink
                        to="/ui-components/alerts"
                        className="dropdown-item"
                      >
                        Alerts
                      </NavLink>
                      <NavLink
                        to="/ui-components/badges"
                        className="dropdown-item"
                      >
                        Badges
                      </NavLink>
                      <NavLink
                        to="/ui-components/buttons"
                        className="dropdown-item"
                      >
                        Buttons
                      </NavLink>
                    </Col>

                    <Col md={6} lg={3}>
                      <h5 className="title">Features</h5>
                      <NavLink
                        to="/ui-components/cards"
                        className="dropdown-item"
                      >
                        Cards
                      </NavLink>
                      <NavLink
                        to="/ui-components/dropdowns"
                        className="dropdown-item"
                      >
                        Dropdowns
                      </NavLink>
                      <NavLink
                        to="/ui-components/forms"
                        className="dropdown-item"
                      >
                        Forms
                      </NavLink>
                    </Col>

                    <Col md={6} lg={3}>
                      <h5 className="title">Features</h5>
                      <NavLink
                        to="/ui-components/list-groups"
                        className="dropdown-item"
                      >
                        List Groups
                      </NavLink>
                      <NavLink
                        to="/ui-components/modals"
                        className="dropdown-item"
                      >
                        Modals
                      </NavLink>
                      <NavLink
                        to="/ui-components/progress-bars"
                        className="dropdown-item"
                      >
                        Progress Bars
                      </NavLink>
                    </Col>

                    <Col md={6} lg={3}>
                      <h5 className="title">Features</h5>
                      <NavLink
                        to="/ui-components/tables"
                        className="dropdown-item"
                      >
                        Tables
                      </NavLink>
                      <NavLink
                        to="/ui-components/tabs"
                        className="dropdown-item"
                      >
                        Tabs
                      </NavLink>
                      <NavLink
                        to="/signup"
                        className="dropdown-item"
                        target="_blank"
                      >
                        Signup
                      </NavLink>
                    </Col>
                  </Row>
                </Container>
              </NavDropdown>
            </Nav> */}

          {/* <Form
              className="nav-search-form d-none d-sm-block"
              onSubmit={handleSubmit}
              action="/search/"
            >
              <FormControl
                type="text"
                value={term}
                onChange={(e) => setTerm({ term: e.target.value })}
                placeholder="Search..."
              />

              <Button className="search-success" type="submit">
                <Icon.Search className="icon" />
              </Button>
            </Form> */}

          <Nav className="ms-auto right-nav">
            {/* <NavDropdown
                title={
                  <div className="count-info">
                    <Icon.Mail className="icon" />
                    <span className="ci-number theme-bg">
                      <span className="ripple theme-bg"></span>
                      <span className="ripple theme-bg"></span>
                      <span className="ripple theme-bg"></span>
                    </span>
                  </div>
                }
                id="basic-nav-dropdown"
                className="message-box d-none d-sm-block"
              >
                <NavLink to="#" className="dropdown-item">
                  <div className="message-item">
                    <span className="user-pic">
                      <Image src={user1} alt="User Image" roundedCircle />
                      <span className="profile-status online"></span>
                    </span>

                    <span className="chat-content">
                      <h5 className="message-title">Aaron Rossi</h5>
                      <span className="mail-desc">
                        Just sent a new comment!
                      </span>
                    </span>
                    <span className="time">0 seconds ago</span>
                  </div>
                </NavLink>

                <NavLink to="#" className="dropdown-item">
                  <div className="message-item">
                    <span className="user-pic">
                      <Image src={user2} alt="User Image" roundedCircle />
                      <span className="profile-status ofline"></span>
                    </span>

                    <span className="chat-content">
                      <h5 className="message-title">Marco Gomez</h5>
                      <span className="mail-desc">
                        Just sent a new comment!
                      </span>
                    </span>
                    <span className="time">5 minutes ago</span>
                  </div>
                </NavLink>

                <NavLink to="#" className="dropdown-item">
                  <div className="message-item">
                    <span className="user-pic">
                      <Image src={user3} alt="User Image" roundedCircle />
                      <span className="profile-status away"></span>
                    </span>

                    <span className="chat-content">
                      <h5 className="message-title">Mitch Petty</h5>
                      <span className="mail-desc">
                        Just sent a new comment!
                      </span>
                    </span>
                    <span className="time">9:00 AM</span>
                  </div>
                </NavLink>

                <Link to="/inbox/" className="dropdown-item">
                  See all e-mails
                  <Icon.ChevronRight className="icon" />
                </Link>
              </NavDropdown>

              <NavDropdown
                title={
                  <div className="count-info">
                    <Icon.Bell className="icon" />
                    <span className="ci-number">
                      <span className="ripple"></span>
                      <span className="ripple"></span>
                      <span className="ripple"></span>
                    </span>
                  </div>
                }
                id="basic-nav-dropdown"
                className="message-box"
              >
                <NavLink to="#" className="dropdown-item">
                  <div className="message-item">
                    <span className="user-pic">
                      <Image src={user1} alt="User Image" roundedCircle />
                      <span className="profile-status online"></span>
                    </span>

                    <span className="chat-content">
                      <h5 className="message-title">Aaron Rossi</h5>
                      <span className="mail-desc">
                        Just sent a new comment!
                      </span>
                    </span>
                    <span className="time">0 seconds ago</span>
                  </div>
                </NavLink>

                <NavLink to="#" className="dropdown-item">
                  <div className="message-item">
                    <span className="user-pic">
                      <Image src={user2} alt="User Image" roundedCircle />
                      <span className="profile-status ofline"></span>
                    </span>

                    <span className="chat-content">
                      <h5 className="message-title">Marco Gomez</h5>
                      <span className="mail-desc">
                        Just sent a new comment!
                      </span>
                    </span>
                    <span className="time">5 minutes ago</span>
                  </div>
                </NavLink>

                <NavLink to="#" className="dropdown-item">
                  <div className="message-item">
                    <span className="user-pic">
                      <Image src={user3} alt="User Image" roundedCircle />
                      <span className="profile-status away"></span>
                    </span>

                    <span className="chat-content">
                      <h5 className="message-title">Mitch Petty</h5>
                      <span className="mail-desc">
                        New order received! <span className="amount">$250</span>
                      </span>
                    </span>
                    <span className="time">9:00 AM - 02-02-2019</span>
                  </div>
                </NavLink>

                <Link to="/notifications/" className="dropdown-item">
                  Check all notifications
                  <Icon.ChevronRight className="icon" />
                </Link>
              </NavDropdown> */}

            <NavDropdown
              title={
                <div className="menu-profile">
                  <span className="name">
                    {gameStudio
                      ? gameStudio.gs_name.toUpperCase()
                      : userProfile
                      ? userProfile.first_name.toUpperCase()
                      : ""}
                  </span>
                  <Image src={profile} alt="Profile Image" roundedCircle />
                </div>
              }
              id="basic-nav-dropdown"
              className="profile-nav-item"
            >
              <NavLink to="/profile/" className="dropdown-item">
                <Icon.User className="icon" />
                Profile
              </NavLink>
              <NavLink
                to="/addUser/"
                className="dropdown-item"
                activeClassName="drpMenu"
              >
                <Icon.UserPlus className="icon" />
                Users
              </NavLink>
              {/* <NavLink to="/inbox/" className="dropdown-item">
                  <Icon.Inbox className="icon" />
                  Mailbox
                </NavLink> */}
              {/* <NavLink to="/chat/" className="dropdown-item">
                  <Icon.HelpCircle className="icon" />
                  Support
                </NavLink> */}
              <NavLink to="/profile-settings/" className="dropdown-item">
                <Icon.Settings className="icon" />
                Settings
              </NavLink>
              <NavLink to="/" className="dropdown-item" onClick={handleLogout}>
                <Icon.LogOut className="icon" />
                Logout
              </NavLink>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      {/* Side Menu File Path: src/components/Navigation/SideMenu/SideMenu.js */}
      {!false ? (
        <SideMenuLight sideMenu={sideMenu} />
      ) : (
        <SideMenuLight sideMenu={sideMenu} />
      )}

      {/*  */}

      {/* Side Menu File Path: src/components/Common/ColorSwitch.js */}
      {/* <ColorSwitch onClick={onSideMenuHandler} /> */}
    </div>
  );
};

export default Navigation;
